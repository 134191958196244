.actions__info {
    display: flex;
    flex-direction: column;

}

.actions {
    display: flex;
    flex-direction: column;
}

.val {
    display: flex;
    flex-direction: column;
    gap: 5px;

}